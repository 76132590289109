<template>
  <div>
    <b-card>
      <b-row>
        <b-col cols="12">
          <b-form-group
            class="font-small-3"
            label="기준 사이트"
            label-for="site-select"
            style="max-width: 500px"
          >
            <b-form-select
              id="site-select"
              v-model="siteSelected"
              :options="authSite"
              class="font-small-3"
              size="sm"
            />
          </b-form-group>
        </b-col>
      </b-row>
    </b-card>
    <b-card>
      <div>
        <!-- IP차단 -->
        <h4 class="">
          <feather-icon
            icon="BoxIcon"
            style="width: 18px; height: 18px"
          />
          알람 관리
          <b-badge
            variant="primary"
            style="font-size: xx-small"
          >
            완료
          </b-badge>
        </h4>
        <hr>
        <div class="m-2">
          <!-- Per Page -->
          <b-row>
            <!-- Per Page -->
            <b-col
              cols="6"
              md="6"
              class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
            >
              <label>Show</label>
              <b-form-select
                v-model="perPage"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="pageOptions"
                size="sm"
                :clearable="false"
                class="per-page-selector d-inline-block mx-50"
              />
            </b-col>
            <!-- Search -->
            <b-col
              cols="12"
              md="6"
            >
              <!--                <div class="d-flex justify-content-end">-->
              <b-form-group
                class="mb-0"
              >
                <b-input-group size="sm">
                  <b-form-input
                    id="filterInput"
                    v-model="filter"
                    type="search"
                    placeholder="Type to Search"
                  />
                  <b-input-group-append>
                    <b-button
                      :disabled="!filter"
                      @click="filter = ''"
                    >
                      Clear
                    </b-button>
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
              <!--                </div>-->
            </b-col>
          </b-row>
        </div>
        <!-- Table -->
        <b-table
          responsive
          show-empty
          hover
          small
          empty-text="No matching records found"
          :per-page="perPage"
          :items="fetchSetAlarms"
          :fields="fields1"
          :filter="filter"
          :filter-included-fields="filterOn"
          style="text-align: center !important; font-size: small"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :sort-direction="sortDirection"
          @filtered="onFiltered"
          :current-page="currentPage"
        >
          <template #cell(index)="data">
            {{ totalRows - (perPage * (currentPage - 1) + data.index) }}
          </template>
          <template #cell(title)="data">
            <div style="text-align: center; color: #3df9ff; min-width: 5rem" @click="alarmsRoute(data.item.title)">
              {{ data.item.title }}
            </div>
          </template>
          <template #cell(comment)="data">
            <div style="text-align: left; min-width: 11rem">
              {{ data.item.comment }}
            </div>
          </template>
          <template #cell(alarmsSound)="data">
            <div>
              <b-button
                class=""
                variant="outline-success"
                size="sm"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                :disabled="isPlaying"
                @click="playSound(data.item.alarmsSound)"
              >
                <span v-if="isPlaying" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                <feather-icon
                  v-else
                  size="10"
                  icon="PlayIcon"
                  class=""
                />
              </b-button>
            </div>
          </template>
          <template #cell(updateAlarmsSound)="data">
            <div>
              <b-button
                class=""
                variant="outline-warning"
                size="sm"
                style="font-size: smaller"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                @click.stop="openModal(data.item.alarmsSound)"
              >
                등록
              </b-button>
            </div>
          </template>
          <template #cell(alarmsCnt)="data">
            <b-form-select
              id="alarmsCnt"
              :value="data.item.alarmsCnt"
              style="text-align: center; color:rgb(229,166,48); max-width: 5rem"
              size="sm"
              class="center"
              :options="options2"
              @change="updateParam('fetchSetAlarms','alarmsCnt', $event, data.index)"
            />
          </template>
          <template #cell(alarmsInterval)="data">
            <b-form-select
              id="alarmsInterval"
              :value="data.item.alarmsInterval"
              style="text-align: center; color:rgb(229,166,48); max-width: 5rem"
              size="sm"
              class="center"
              :options="options3"
              @change="updateParam('fetchSetAlarms','alarmsInterval', $event, data.index)"
            />
          </template>
          <template #cell(useYn)="data">
            <b-form-select
              id="useYn"
              :value="data.item.useYn"
              style="text-align: center; color:rgb(229,166,48); max-width: 5rem"
              size="sm"
              class="center"
              :options="options1"
              @change="updateParam('fetchSetAlarms','useYn', $event, data.index)"
            />
          </template>
        </b-table>
        <b-col
          id="basic1-btn"
          class="mt-1"
          cols="12"
        >
          <b-button
            ref="submit"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            type="submit"
            variant="primary"
            class="w-auto content-right"
            size="sm"
            @click="submit('updateSetAlarms')"
          >
            저장
          </b-button>
        </b-col>
      </div>
    </b-card>
    <b-modal
      ref="uploadSoundModal"
      small
      no-close-on-backdrop
      title="파일 업로드"
      centered
      button-size="sm"
      cancel-title="취소"
      ok-title="저장"
      @ok="uploadSound()"
      :ok-disabled="fileTooLarge"
      @cancel="closeModal()"
    >
      <!-- Accept specific image formats by extension -->
      <label
        class="mt-1"
      >5mb 이하, mp3 파일만 업로드 가능합니다</label>
      <b-form-file
        id="extension"
        accept=".mp3"
        v-model="uploadFile"
        @change="checkFileSize"
      />
      <div v-if="fileTooLarge" class="text-danger">파일 크기가 5MB를 초과합니다.</div>
      <div
        class="text-dark small mb-1"
      >
        파일 크기: {{ fileSizeString }}
      </div>
    </b-modal>
  </div>
</template>

<script>
import {
  BCard,
  BTable,
  BButton,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BPagination,
  BFormSelect,
  BInputGroupAppend,
  BInputGroup,
  BBadge,
  BFormFile,
  VBTooltip,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import Swal from 'sweetalert2'
import { createNamespacedHelpers } from 'vuex'
import { FETCH_SET_ALARMS } from '@/store/settings/action'
import { UPDATE_SET_ALARMS } from '@/store/settings/mutation'

const settingsStore = createNamespacedHelpers('settingsStore')

export default {
  components: {
    BRow,
    BCard,
    BTable,
    BCol,
    BFormGroup,
    BFormInput,
    BButton,
    BPagination,
    BFormSelect,
    BInputGroupAppend,
    BInputGroup,
    BBadge,
    BFormFile,
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      userData: JSON.parse(localStorage.getItem('userData')),
      authSite: localStorage.getItem('authSite').split(','),
      siteSelected: localStorage.getItem('authSiteSelected'),
      userIp: localStorage.getItem('ip'),
      audio: null, // audio 객체를 저장할 변수
      endedListener: null, // 'ended' 이벤트 리스너 함수를 저장할 변수
      isPlaying: false,
      uploadTarget: '',
      uploadFile: null,
      fileTooLarge: false,
      fileSizeString: '',
      options1: [
        { value: 'N', text: 'N' },
        { value: 'Y', text: 'Y' },
      ],
      options2: [
        { value: 1, text: '1' },
        { value: 2, text: '2' },
        { value: 3, text: '3' },
        { value: 4, text: '4' },
        { value: 5, text: '5' },
        { value: 6, text: '6' },
        { value: 7, text: '7' },
        { value: 8, text: '8' },
        { value: 9, text: '9' },
        { value: 10, text: '10' },
        { value: 99, text: '99' },
      ],
      options3: [
        { value: 1, text: '1' },
        { value: 2, text: '2' },
        { value: 3, text: '3' },
        { value: 4, text: '4' },
        { value: 5, text: '5' },
      ],
      perPage: 10,
      pageOptions: [10, 20, 30],
      totalRows: 1,
      currentPage: 1,
      sortBy: 'idx',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      fields1: [
        {
          key: 'index', sortable: false, label: 'No.', thClass: 'col1',
        },
        {
          key: 'title', sortable: false, label: '제목', thClass: 'col2',
        },
        {
          key: 'comment', sortable: false, label: '알람 설명', thClass: 'col3',
        },
        {
          key: 'alarmsSound', sortable: false, label: '알람음', thClass: 'col4',
        },
        {
          key: 'updateAlarmsSound', sortable: false, label: '알람음 등록', thClass: 'col4',
        },
        {
          key: 'alarmsCnt', sortable: false, label: '알람 횟수', thClass: 'col4',
        },
        {
          key: 'alarmsInterval', sortable: false, label: '알람 간격(초)', thClass: 'col4',
        },
        {
          key: 'useYn', sortable: false, label: '사용여부',
        },
      ],
      tableItems: [],
    }
  },
  computed: {
    ...settingsStore.mapGetters({
      fetchSetAlarms: 'fetchSetAlarms',
      fetchSetSiteAdmin: 'fetchSetSiteAdmin',
    }),
  },
  watch: {
    siteSelected: {
      handler(event) {
        this.fetchData()
        localStorage.setItem('authSiteSelected', event)
        this.$store.commit('footerStore/setSelectedSiteIndex', this.authSite.indexOf(event))
      },
      immediate: false,
      deep: false,
    },
  },
  mounted() {
    this.fetchData()
  },
  methods: {
    ...settingsStore.mapActions({
      $fetchSetAlarms: FETCH_SET_ALARMS,
      $updateSetAlarms: UPDATE_SET_ALARMS,
    }),
    updateParam(t, e, v, i) {
      // console.log(t)
      // console.log(e)
      // console.log(v)
      const events = { t, e, v, i }
      // this.info[e] = v
      // store에 param을 update 한다
      this.$store.commit('settingsStore/updateManyParam', events)
    },
    truncateText(text, maxLength) {
      if (!text) return
      if (text.length <= maxLength) {
        return text
      } else {
        return text.substring(0, maxLength) + '...'
      }
    },
    async fetchData() {
      const mySite = this.siteSelected
      await this.$fetchSetAlarms({
        site: mySite,
      })
      this.totalRows = this.fetchSetAlarms.length
    },
    // IP 차단
    async submit(target, data) {
      await Swal.fire({
        title: '변경 내용을 저장 하시겠습니까?',
        // text: Number(this.desiredBet).toLocaleString(),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes',
        background: 'rgb(3,11,37)',
        customClass: {
          cancelButton: 'btn btn-dark',
          confirmButton: 'btn btn-primary mr-1',
        },
      }).then(result => {
        if (result.isConfirmed) {
          this.confirmYes(target, data)
        }
      })
    },
    // confirm Yes
    confirmYes(target) {
      const mySite = this.siteSelected
      try {
        if (target === 'updateSetAlarms') {
          this.$updateSetAlarms({
            site: mySite,
            userid: this.userData.userid,
            userIp: this.userIp,
          })
        }
      } catch {
        console.error()
      } finally {
        Swal.fire({
          title: '저장 완료',
          icon: 'success',
          timer: 5000,
          background: 'rgb(3,11,37)',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
        }).then(result => {
          this.fetchData()
        })
      }
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    playSound(alarmsSound) {
      this.isPlaying = true
      this.audio = new Audio(`https://agress-assets.s3.ap-northeast-2.amazonaws.com/sounds/${alarmsSound}.mp3`)
      this.audio.play().then(() => {
        this.endedListener = () => this.stopSound()
        this.audio.addEventListener('ended', this.endedListener)
      }).catch(error => {
        console.error('Error playing audio:', error)
      })
    },
    stopSound() {
      this.isPlaying = false
      if (this.audio) {
        this.audio.pause()
        this.audio.currentTime = 0
        if (this.endedListener) {
          this.audio.removeEventListener('ended', this.endedListener)
        }
        this.audio = null
      }
    },
    openModal(target) {
      this.uploadTarget = target // 모달에 넘겨줄 데이터 설정
      this.$refs.uploadSoundModal.show()
    },
    closeModal() {
      this.fileSizeString = ''
    },
    formatFileSize(size) {
      if (size < 1024) {
        return size + ' bytes'
      } else if (size < 1048576) {
        return (size / 1024).toFixed(2) + ' KB'
      } else {
        return (size / 1048576).toFixed(2) + ' MB'
      }
    },
    checkFileSize(event) {
      const selectedFile = event.target.files[0]
      if (selectedFile) {
        this.uploadFile = selectedFile
        const fileSize = selectedFile.size
        this.fileSizeString = this.formatFileSize(fileSize)
        if (fileSize > 5 * 1024 * 1024) { // 5MB 이상인 경우
          this.fileTooLarge = true
        } else {
          this.fileTooLarge = false
        }
      }
    },
    uploadSound() {
      if (!this.uploadFile || this.fileTooLarge) {
        // 파일이 없거나 파일 크기가 너무 큰 경우 업로드하지 않음
        return
      }
      console.log(this.uploadTarget)
      console.log(this.uploadFile)
      const params = {
        Bucket: 'agress-assets', // S3 버킷 이름
        Key: `sounds/${this.uploadTarget}.mp3`, // 업로드될 파일 이름
        Body: this.uploadFile,
        ContentType: 'audio/mp3',
      }
      this.$s3.upload(params, (err, data) => {
        if (err) {
          console.error('업로드 실패:', err)
          Swal.fire({
            title: '업로드 실패',
            icon: 'warning',
            timer: 5000,
            background: 'rgb(3,11,37)',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
          }).then(result => {
            this.fetchData()
          })
        } else {
          console.log('업로드 성공:', data.Location)
          Swal.fire({
            title: '저장 완료',
            icon: 'success',
            timer: 5000,
            background: 'rgb(3,11,37)',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
          }).then(result => {
            this.fetchData()
          })
        }
      })
    },
    alarmsRoute(target) {
      if (target === '충전') {
        this.$router.push({ name: 'deposit-list' }).catch(() => {})
      }
      if (target === '환전') {
        this.$router.push({ name: 'exchange-list' }).catch(() => {})
      }
      if (target === '문의') {
        this.$router.push({ name: 'messages-history-user' }).catch(() => {})
      }
      if (target === '신규') {
        this.$router.push({ name: 'users-Signup-list' }).catch(() => {})
      }
      if (target === '환급') {
        if (this.$route.name !== 'refund-hit-result') {
          this.$router.push({ name: 'refund-hit-result' }).catch(() => {})
        } else {
          // 중복 Router 동작 우회로 EventBus 사용함
          this.$EventBus.$emit('QuickBtnFetchREQUEST')
        }
      }
      if (target === '총환') {
        this.$router.push({ name: 'branch-cashout-list' }).catch(() => {})
      }
      if (target === '고액') {
        // 고액 알람은 실시간 휘발성 알람으로 로컬스토리지를 사용 (클릭시 0으로 초기화함)
        if (this.$route.name !== 'refund-hit-result') {
          this.$router.push({ name: 'refund-hit-result', params: { targetTab: '대기함', targetOption: 'YET' } }).catch(() => {})
        } else {
          // 중복 Router 동작 우회로 EventBus 사용함
          this.$EventBus.$emit('QuickBtnFetchYET')
        }
      }
      if (target === '공지') {
        this.$router.push({ name: 'notice-admin' }).catch(() => {})
      }
      if (target === '비회원') {
        this.$router.push({ name: 'messages-guest' }).catch(() => {})
      }
    },
  },
}

</script>

<style scoped>
.per-page-selector {
  width: 90px;
}
</style>
